import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react'
import {
  CChartBar,
  CChartDoughnut,
  CChartLine,
  CChartPie,
  CChartPolarArea,
  CChartRadar,
} from '@coreui/react-chartjs'
import { DocsCallout } from 'src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Flex, Input, Upload, Select, Spin, Checkbox } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import jquery from 'jquery';
import InputNumberFormat from "../../components/InputNumberFormat.js";
import BraftEditor from 'braft-editor';
import { FilePdfOutlined } from '@ant-design/icons';
import { apiUrls } from '../../apiUrls.js';
import { useNavigate } from 'react-router-dom';
import HideSidebarInMobileMode from "../../components/HideSidebarInMobileMode.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const baseURL = apiUrls.baseURL;
const { TabPane } = Tabs;
const { Option } = Select;

const ProgramForm = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [deadlineDate, setDeadLineDate] = useState(null);
  const [dropDownDiscountTypes, setDropDownDiscountType] = useState([]);
  const [dropDownWorkingGroup, setDropDownWorkingGroup] = useState([]);
  const [dropDownRequirements, setDropDownRequirements] = useState([]);
  const [dropDownGenders, setDropDownGenders] = useState([]);
  const [dropDownLevel, setDropDownLevel] = useState([]);
  const [dropDownType, setDropDownType] = useState([]);
  const [dropDownLeader, setDropDownLeaders] = useState([]);
  const permissions = useSelector((state) => state.permissions.permissions);
  const accessShowInSite = permissions.includes('createblog');
  const [renderedTabs, setRenderedTabs] = useState([0]);
  const navigate = useNavigate();
  //eslint-disable-next-line react-hooks/rules-of-hooks  
  const { id } = useParams();

  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(null)
  );

  const handleEditorChange = (content) => {
    const modifiedContent = content.toHTML().replace(/<figure/g, '<img').replace(/\/>/g, '></img>');
    setEditorState(modifiedContent);
    setData({ ...data, report: modifiedContent })
  };

  useEffect(() => {
    if (id !== undefined)
      fetchData(id)
    fetchDiscountType();
    fetchWorkingGroup();
    fetchRequirements();
    fetchLevel();
    fetchType();
    fetchLeaders();
    fetchGender();
  }, [])

  const fetchData = async (id) => {
    setLoading(true);
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/program/getbyid?id=${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      var result = response.data.data;

      if (result.startDate != null) {
        var resultStartDate = result.startDate.split('/');
        setStartDate({
          year: parseInt(resultStartDate[0], 10),
          month: parseInt(resultStartDate[1], 10),
          day: parseInt(resultStartDate[2], 10)
        });
      }
      if (result.endDate != null) {
        var resultEndDate = result.endDate.split('/');
        setEndDate({
          year: parseInt(resultEndDate[0], 10),
          month: parseInt(resultEndDate[1], 10),
          day: parseInt(resultEndDate[2], 10)
        });
      }
      if (result.deadlineDate != null) {
        var resultDeadlineDate = result.deadlineDate.split('/');
        setDeadLineDate({
          year: parseInt(resultDeadlineDate[0], 10),
          month: parseInt(resultDeadlineDate[1], 10),
          day: parseInt(resultDeadlineDate[2], 10)
        });
      }
      setEditorState(BraftEditor.createEditorState(result.report));
      setData(result);
      setLoading(false);
    } catch (error) {
      if (error.response.status == 401)
        navigate('/');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchDiscountType = async () => {
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/program/getDiscountTypeDropdownItems`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })

      var result = response.data.data;
      setDropDownDiscountType(result);
    } catch (error) {
    } finally {
    }
  };

  const fetchType = async () => {
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/program/getTypeDropdownItems`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })

      var result = response.data.data;
      setDropDownType(result);
    } catch (error) {
    } finally {
    }
  };

  const fetchLevel = async () => {
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/program/getLevelDropdownItems`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })

      var result = response.data.data;
      setDropDownLevel(result);
    } catch (error) {
    } finally {
    }
  };

  const fetchGender = async () => {
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/user/getGenderDropdownItems`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })

      var result = response.data.data;
      setDropDownGenders(result);
    } catch (error) {
    } finally {
    }
  };

  const fetchRequirements = async () => {
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/program/getRequirementsDropdownItems`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })

      var result = response.data.data;
      setDropDownRequirements(result);
    } catch (error) {
    } finally {
    }
  };

  const fetchWorkingGroup = async () => {
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/workingGroupCategory/getDropdownItems`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })

      var result = response.data.data;
      setDropDownWorkingGroup(result);
    } catch (error) {
    } finally {
    }
  };

  const fetchLeaders = async () => {
    const token = localStorage.getItem('damavandclub-token')
    try {
      const response = await axios.get(`${baseURL}/user/getLeaderDropdownItems`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      })

      var result = response.data.data;
      setDropDownLeaders(result);
    } catch (error) {
    } finally {
    }
  };

  const setSelectedStartDate = async (date) => {
    setStartDate({
      year: date.year,
      month: date.month,
      day: date.day
    });
    setData({ ...data, startDate: `${date.year}/${date.month}/${date.day}` })
  }

  const setSelectedEndDate = async (date) => {
    setEndDate({
      year: date.year,
      month: date.month,
      day: date.day
    });
    setData({ ...data, endDate: `${date.year}/${date.month}/${date.day}` })
  }

  const mediasProps = {
    name: 'file',
    action: `${baseURL}/media/UploadFiles?containerkey=program&isPublic=true`, // Replace with your upload endpoint
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('damavandclub-token'),
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        let response = info.file.response.data[0];
        let newMedia = { url: response.url, id: response.id };
        const newState = { ...data };
        const newMedias = [...data.medias, newMedia];
        newState.medias = newMedias;
        setData(newState)
        jquery(".ant-upload-list-text-container").remove();
      } else if (info.file.status === 'error') {
        return toast.warning(info.file.response.Msg, {
          position: 'bottom-right',
          autoClose: 3000, // milliseconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
  };

  const reportFileProps = {
    name: 'file',
    action: `${baseURL}/media/UploadFiles?containerkey=program&isPublic=true`, // Replace with your upload endpoint
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('damavandclub-token'),
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        let response = info.file.response.data[0];
        let reportFile = { url: response.url, id: response.id };
        setData({ ...data, reportFile: reportFile, reportMediaId: reportFile.id })
        jquery(".ant-upload-list-text-container").remove();
      } else if (info.file.status === 'error') {
        return toast.warning(info.file.response.Msg, {
          position: 'bottom-right',
          autoClose: 3000, // milliseconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
  };

  const setSelectedDeadLineDate = async (date) => {
    setDeadLineDate({
      year: date.year,
      month: date.month,
      day: date.day
    });
    setData({ ...data, deadlineDate: `${date.year}/${date.month}/${date.day}` })
  }

  const removeMedia = (mediaId) => {
    const newState = { ...data };
    var filterMedias = data.medias.filter(item => item.id != mediaId);
    newState.medias = filterMedias;
    setData(newState)
  };

  const onSelect = useCallback((index) => {
    setRenderedTabs((renderedTabs) =>
      renderedTabs.includes(index)
        ? renderedTabs
        : renderedTabs.concat(index)
    )
  }, []);

  const save = async () => {

    if (data.discountAmount != undefined && data.discountAmount != "" && data.discountType == undefined) return toast.warning("نوع تخفیف انتخاب نشده است!", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.nameFa == undefined) return toast.warning("عنوان برنامه انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.capacity == undefined) return toast.warning("تعداد نفرات برنامه انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.workingGroupCategoryId == undefined) return toast.warning("کارگروه انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.programType == undefined) return toast.warning("نوع برنامه انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.programLevel == undefined) return toast.warning("سطح برنامه انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.leaderId == undefined) return toast.warning("سرپرست برنامه انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.startDate == undefined) return toast.warning("تاریخ شروع انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.endDate == undefined) return toast.warning("تاریخ پایان انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    if (data.deadlineDate == undefined) return toast.warning("مهلت ثبت نام انتخاب نشده است", {
      position: 'bottom-right',
      autoClose: 3000, // milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    setLoadingSave(true);
    try {
      const token = localStorage.getItem('damavandclub-token')

      if (id !== undefined) {
        var response = await axios.post(`${baseURL}/program/update`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        })
        toast.success(response.data.message, {
          position: 'bottom-right',
          autoClose: 3000, // milliseconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        var response2 = await axios.post(`${baseURL}/program/updatemedias?id=${id}&key=Program`, data.medias.map(x => x.id), {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        })
        toast.success(response2.data.message, {
          position: 'bottom-right',
          autoClose: 3000, // milliseconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        var response = await axios.post(`${baseURL}/program/notifyProgram`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        })
        toast.success(response.data.message, {
          position: 'bottom-right',
          autoClose: 3000, // milliseconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      setLoadingSave(false);
    } catch (error) {
      toast.error(error.response.data.Msg, {
        position: 'bottom-right',
        autoClose: 6000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        //className: 'custom-toast',
        pauseOnHover: true,
        draggable: true,
      });
      setLoadingSave(false);
    } finally {
      setLoadingSave(false);
    }
  }

  if (loading) {
    return <Spin size="large" />
  }

  return (
    <div className='row'>
      <HideSidebarInMobileMode />
      <div style={{
        backgroundColor: 'white',
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
        height: '100%',
        padding: 10
      }}>
        {id !== undefined ? <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
          type="primary"><label>ویرایش برنامه</label></Button> : <Button onClick={() => save()} loading={loadingSave} style={{ backgroundColor: 'rgb(0 151 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
            type="primary"><label>ثبت برنامه</label></Button>}


        <Tabs onSelect={onSelect}>
          <TabList>
            <Tab>فارسی</Tab>
            <Tab>انگلیسی</Tab>
            {id !== undefined && <Tab>تصاویر</Tab>}
            {id !== undefined && <Tab>گزارش برنامه</Tab>}
            {id !== undefined && <Tab>آپلود فایل گزارش برنامه</Tab>}
          </TabList>

          <TabPanel forceRender={renderedTabs.includes(0)}>
            <label>عنوان برنامه</label>
            <Input value={data.nameFa} onChange={(event) => setData({ ...data, nameFa: event.target.value })} style={{ marginTop: 5 }} size="large" />

            <label className='margintop-18'>هزینه برنامه(ریال) با اعداد انگلیسی وارد کنید</label>
            <InputNumberFormat value={data.amount} onValueChange={(value) => setData({ ...data, amount: value })} />

            <label className='margintop-18'>مبلغ تخفیف(ریال)-(اگر ندارد خالی بگذارید)</label>
            <InputNumberFormat value={data.discountAmount} onValueChange={(value) => setData({ ...data, discountAmount: value })} />

            {data.discountAmount !== undefined && data.discountAmount !== "" && data.discountAmount !== null ? <div><label className='margintop-18'>نوع تخفیف</label> <Select
              style={{ width: '100%' }}
              placeholder="انتخاب نوع تخفیف"
              onChange={(value) => setData({ ...data, discountType: value })}
              value={data.discountType}
            >
              {dropDownDiscountTypes?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.displayName}
                </Option>
              ))}
            </Select></div> : <></>}
            <label className='margintop-18'>تعداد نفرات برنامه(با اعداد انگلیسی)</label>
            <Input style={{ textAlign: 'right', marginTop: 5 }} type='number' value={data.capacity} onChange={(event) => setData({ ...data, capacity: event.target.value })} size="large" />
            <label className='margintop-18'>کارگروه</label> <Select
              style={{ width: '100%' }}
              placeholder="انتخاب گارگروه"
              onChange={(value) => setData({ ...data, workingGroupCategoryId: value })}
              value={data.workingGroupCategoryId}
            >
              {dropDownWorkingGroup?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.displayName}
                </Option>
              ))}
            </Select>

            {data.workingGroupCategoryId === 11 && <><label className='margintop-18'>جنسیت(در صورت مشترک بودن انتخاب نکنید)</label>
              <Select
                style={{ width: '100%' }}
                placeholder="انتخاب جنسیت"
                onChange={(value) => setData({ ...data, gender: value })}
                value={data.gender}
              >
                {dropDownGenders?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.displayName}
                  </Option>
                ))}
              </Select></>}

            {data.workingGroupCategoryId === 11 && <><label className='margintop-18'>لینک گروه تلگرامی کلاس</label>
              <Input value={data.socialMediaGroupLink} onChange={(event) => setData({ ...data, socialMediaGroupLink: event.target.value })} style={{ marginTop: 5 }} size="large" /></>}

            <label className='margintop-18'>نوع برنامه</label> <Select
              style={{ width: '100%' }}
              placeholder="انتخاب نوع برنامه"
              onChange={(value) => {
                if (value == 1)
                  alert("نوع برنامه ای که انتخاب کرده اید شامل چرخه کارگروه باشگاه برای اجرا نخواهد شد و صرفا در آرشیو برنامه ها ذخیره خواهد شد")
                setData({ ...data, programType: value })
              }

              }
              value={data.programType}
            >
              {dropDownType?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.displayName}
                </Option>
              ))}
            </Select>
            <label className='margintop-18'>سطح برنامه</label> <Select
              style={{ width: '100%' }}
              placeholder="انتخاب سطح برنامه"
              onChange={(value) => setData({ ...data, programLevel: value })}
              value={data.programLevel}
            >
              {dropDownLevel?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.displayName}
                </Option>
              ))}
            </Select>
            <label className='margintop-18'>سرپرست</label>
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="انتخاب سرپرست"
              notFoundContent="لطفا کمی صبر کنید..."
              optionFilterProp="label"
              onChange={(value) => setData({ ...data, leaderId: value })}
              value={data.leaderId}
            >
              {dropDownLeader?.map((item) => (
                <Option key={item.id} label={item.displayName} value={item.id}>
                  {item.displayName}
                </Option>
              ))}
            </Select>
            <label className='margintop-18'>انتخاب کمک سرپرست ها(اختیاری)</label>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              showSearch
              placeholder="انتخاب کمک سرپرست ها"
              notFoundContent="لطفا کمی صبر کنید..."
              optionFilterProp="label"
              onChange={(value) => setData({ ...data, programAssistantLeaderIds: value })}
              value={data.programAssistantLeaderIds}
            >
              {dropDownLeader?.map((item) => (
                <Option key={item.id} label={item.displayName} value={item.id}>
                  {item.displayName}
                </Option>
              ))}
            </Select>
            <label className='margintop-18'>انتخاب پیش نیاز ها(اختیاری)</label>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              showSearch
              placeholder="انتخاب پیش نیازها"
              optionFilterProp="label"
              onChange={(value) => setData({ ...data, programRequirementIds: value })}
              value={data.programRequirementIds}
            >
              {dropDownRequirements?.map((item) => (
                <Option key={item.id} label={item.displayName} value={item.id}>
                  {item.displayName}
                </Option>
              ))}
            </Select>
            <label className='margintop-18'>تاریخ شروع</label>
            <div className='margintop-18 center-content customDatePickerWidth'>
              <DatePicker
                value={startDate || null}
                //inputClassName='fullwidth'
                onChange={(value) => setSelectedStartDate(value)}
                //minimumDate={persianToday}
                //calendarClassName="fullwidth"
                shouldHighlightWeekends
                locale='fa'
              />
            </div>
            <label className='margintop-18'>تاریخ پایان</label>
            <div className='margintop-18 center-content customDatePickerWidth'>
              <DatePicker
                value={endDate || null}
                //inputClassName='fullwidth'
                onChange={(value) => setSelectedEndDate(value)}
                //minimumDate={persianToday}
                //calendarClassName="fullwidth"
                shouldHighlightWeekends
                locale='fa'
              />
            </div>
            <label className='margintop-18'>مهلت ثبت نام</label>
            <div className='margintop-18 center-content customDatePickerWidth'>
              <DatePicker
                value={deadlineDate || null}
                //inputClassName='fullwidth'
                onChange={(value) => setSelectedDeadLineDate(value)}
                //minimumDate={persianToday}
                //calendarClassName="fullwidth"
                shouldHighlightWeekends
                locale='fa'
              />
            </div>
            <label className='margintop-18'>توضیحات</label>
            <textarea value={data.descriptionFa} onChange={(event) => setData({ ...data, descriptionFa: event.target.value })}
              style={{
                marginTop: 5,
                resize: 'none',
                width: '100%',
                minHeight: 300
              }} />
            <label className='margintop-18'>سئو</label>
            <Input style={{ marginTop: 5 }} value={data.seoContent} onChange={(event) => setData({ ...data, seoContent: event.target.value })} size="large" />
            {accessShowInSite ? <Checkbox checked={data.isShowInSite} onChange={() => setData({ ...data, isShowInSite: !data.isShowInSite })}>نمایش در سایت</Checkbox> : <></>}
            <br />
            <Checkbox checked={data.isFailed} onChange={() => setData({ ...data, isFailed: !data.isFailed })}>برنامه کنسل شده است</Checkbox>
          </TabPanel>

          <TabPanel forceRender={renderedTabs.includes(1)}>
            <label>عنوان برنامه</label>
            <Input value={data.nameEn} onChange={(event) => setData({ ...data, nameEn: event.target.value })} style={{ marginTop: 5 }} size="large" />
            <label className='margintop-18'>توضیحات</label>
            <textarea value={data.descriptionEn} onChange={(event) => setData({ ...data, descriptionEn: event.target.value })}
              style={{
                marginTop: 5,
                resize: 'none',
                width: '100%',
                minHeight: 400
              }} />
          </TabPanel>

          <TabPanel forceRender={renderedTabs.includes(2)}>
            <Upload
              {...mediasProps}
            >
              <Button icon={<UploadOutlined />} loading={loadingSave} style={{ backgroundColor: 'rgb(0 20 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary" ><label>افزودن تصویر</label></Button>
            </Upload>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {data.medias?.map((media, index) => {
                // eslint-disable-next-line jsx-a11y/alt-text
                return <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                  <img className='margintop-18' style={{ height: 200, width: 200 }} src={media.url}></img>
                  <Button className='margintop-5' onClick={() => removeMedia(media.id)} type='danger'>حذف</Button>
                </div>
              })}
            </div>
          </TabPanel>

          <TabPanel forceRender={renderedTabs.includes(3)}>
            <BraftEditor
              language='en'

              value={editorState}
              onChange={handleEditorChange}
            />
          </TabPanel>

          <TabPanel forceRender={renderedTabs.includes(4)}>
            <Upload
              {...reportFileProps}
            >
              <Button icon={<UploadOutlined />} loading={loadingSave} style={{ backgroundColor: 'rgb(0 20 46)', marginTop: 10, width: '100%', marginBottom: 10 }}
                type="primary" ><label>آپلود فایل گزارش برنامه(فایل pdf)</label></Button>
            </Upload>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {data.reportFile?.id !== null ? <div style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 180,
                height: 180,
                display: 'flex',
              }} onClick={() => window.open(data.reportFile.url)}> <FilePdfOutlined style={{ fontSize: '80px', color: 'red' }} />فایل گزارش برنامه</div> : null}
            </div>
          </TabPanel>

        </Tabs>

      </div>
    </div >
  )
}

export default ProgramForm
